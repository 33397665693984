.navbar,
.navbar-default {
    background-color: black !important;
    width: 100%;
    position: fixed;
    z-index: 100;
}

.socialMediaButtons {
    position: relative;
    float: right;
}

.smb {
    float: right;
    margin-left: 30px;
}

#brandImg {
    background-color: #35393e;
    border-radius: 18px;
}

.link {
    color: #FFFFFF80;
    margin: 8px;
}

.link:hover {
    color: white;
    text-decoration: none; 
}

.link:link { 
    text-decoration: none; 
}

.link:visited { 
    text-decoration: none; 
}

.link:active { 
    text-decoration: none; 
}