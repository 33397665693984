.circle {
    max-width: 80px;
    max-height: 80px;
    position: absolute;
    border-radius: 50px;
    background-color: #343A40;
    animation-name: floating;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    overflow: hidden;
    display: none;
}

.circle:hover {
    background-color: white;
}

.circle.one {
    width: 3vmax;
    height: 3vmax;
    right: 5%;
    top: 20%;
}

.circle.two {
    width: 2vmax;
    height: 2vmax;
    right: 10%;
    top: 60%;
}

.circle.three {
    width: 2.2vmax;
    height: 2.2vmax;
    left: 30%;
    top: 60%;
} 

.circle.four {
    width: 1.5vmax;
    height: 1.5vmax;
    left: 40%;
    top: 20%;
}

.circle.five {
    width: 1vmax;
    height: 1vmax;
    right: 15%;
    top: 40%;
}


@keyframes floating {
    0% { transform: translate(0,  0px); opacity: 1;}
    50%  { transform: translate(0, 50px); opacity: 0.5; }
    100%   { transform: translate(0, -0px); opacity: 1; }   
}

@media screen and (max-height: 800px){
    .circle {
        display: none;
    }
}

@media screen and (min-width: 2100px){
    .circle {
        display: block;
    }
}

