html {
  scroll-behavior: smooth;
  background-color: black;
}

header {
  position: relative;
  background-color: black;
  font-family: "Geologica";
  padding-top: 100px;
  min-width: 290px;
  height: fit-content;
}

#name {
  margin-top: 20px;
  margin-left: 20px;
  color: white;
}

#quote {
  margin-top: 0px;
  margin-left: 40px;
  font-size: 1rem;
  color: white;
}

#projectTitle {
  color: white;
  width: fit-content;
  border-end-end-radius: 18px;
  border-end-start-radius: 18px;
  background-color: black;
  font-family: "Geologica";
  padding: 20px;
  margin-left: calc(10vmin + 120px);
}

#projectSection {
  justify-content: center;
  align-items: center;
  min-width: 250px;
  margin: 20px;
  margin-bottom: 40px;
  background-color: white;
}

#skillsSection {
  background-color: #000000;
  font-family: "Geologica";
  padding: 60px;
  padding-bottom: 20px;
  min-width: 290px;
  color: white;
  justify-content: center;
  align-items: center;
}

.spacer {
  /* height: calc(30vmax - 250px); */
  height: 8vmax;
  max-height: 195px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .project {
    width: 100%;
  }

  .demoContainerIC,
  .demoPhoneContainer,
  .demoContainer {
    padding: 20px;
    padding-top: 40px;
  }

  .demoExtPlayerContainer,
  .demoExtPhoneContainer,
  .demoExtContainer,
  .demoExtPlayerContainer {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .spacer {
    max-height: 300px;
    height: 300px;
  }
}