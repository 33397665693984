#avatar {
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 30vmax;
    max-width: 580px;
}

#infoContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: right;
    align-items: right;
    overflow: hidden;
    margin: auto;
    bottom: 10%;
    right: calc(30vmax + 10% - 80px);
}

#bioBox {
    position: relative;
    background-color: #222222;
    border-radius: 18px;
    width: 40ch;
    color: white;
    margin: 20px;
    margin-top: 40px;
    padding: 20px;
}

.infoBox {
    display: flex;
    flex-direction: row;
    padding: 16px;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    width: fit-content;
    color: white;
}

.infoBox.two {
    margin-right: 20px;
}

.infoBox.three {
    margin-right: 40px;
}

.infoBox.four {
    margin-right: 60px;
}

.infoBoxText {
    margin-left: 10px;
}

@media screen and (max-width: 1359px){
    #infoContainer{
        display: none;
    }
}

@media screen and (1360px <= width <= 1600px) { 
    #infoContainer {
        bottom: 10px;
    }
}

@media screen and (max-width: 750px){
    #avatar{
        width: 250px;
    }

    #bioBox {
        width: 80%;
        height: fit-content;
    }
}

@media screen and (max-width: 950px){
    #avatar{
        right: 5%;
    }
}

@media screen and (min-width: 2100px){
    #infoContainer{
        /* right: calc(580px + 10% - 80px); */
        left: 50%;

    }

    #avatar {
        left: calc(58%);
    }
}