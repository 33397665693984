.project {
    margin: auto;
    position: relative;
    background-color: #eff0f1;
    width: 750px;
    min-width: 240px;
    border-radius: 18px;
    margin-top: 60px;
}

.demoContainer {
    padding: 60px;
    padding-bottom: 0px;
    width: 75%;
    margin: auto;
    position: relative;
}

.D,
.CB,
.DVR {
    padding-bottom: 33px;
}

.LL {
    padding-bottom: 59px;
}

.IC {
    padding-bottom: 1px;
}


.demoPhoneContainer {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 5px;
    width: 40%;
    margin: auto;
    position: relative;
}

.demoExtContainer {
    position: absolute;
    top: 20%;
    right: calc(100% - 40px - 60px);
    width: 30%;
}

.demoExtPhoneContainer {
    position: absolute;
    top: 20%;
    right: calc(100% - 60px);
    width: 45%;
}

.demoExtPlayerContainer {
    position: absolute;
    top: calc(100% - 120px);
    left: calc(60%);
    width: 50%;
}


.descContainer {
    font-family: "Geologica";
    padding: 40px;
}

.open {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .project {
        width: 100%;
    }

    .demoPhoneContainer,
    .demoContainer {
        padding: 20px;
        padding-top: 40px;
    }

    .demoExtPhoneContainer,
    .demoExtContainer,
    .demoExtPlayerContainer {
        display: none;
    }

    .open {
        padding: 8px;
    }
}

@media screen and (1360px <= width <= 1600px) {
    .project {
        width: 650px;
    }

    .D,
    .CB,
    .DVR {
        padding-bottom: 25px;
    }

    .LL {
        padding-bottom: 48px;
    }

    .IC {
        padding-bottom: 0px;
    }

    .demoPhoneContainer {
        padding-bottom: 25px;
    }

    .ICP {
        top: calc(100% - 80px);
    }
}